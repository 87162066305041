import { useRouter } from 'next/router';
import { useEffect } from 'react';

/**
 * Initial page load
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index() {
    const router = useRouter();

    useEffect(() => {
        router
            .replace({
                pathname: '/home'
            })
            .then();
    }, [router]);

    return <nav />;
}
